var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header_wrap",
    class: _vm.headerWrapClass,
    style: _vm.headerWrapStyle
  }, [_c('div', {
    ref: "headerInnerRef",
    staticClass: "header_inner",
    style: _vm.headerInnerStyle
  }, [_c('HeaderTop'), _c('GlobalNavigationBar')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }