var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header_serach",
    class: {
      'is-active': _vm.isSearchHeaderActive
    }
  }, [_c('h2', {
    staticClass: "blind"
  }, [_vm._v("통합검색")]), _vm.visibleState.backButton ? _c('button', {
    staticClass: "btn_header_back",
    on: {
      "click": _vm.backClickHandler
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("이전 페이지 이동")])]) : _c('button', {
    staticClass: "btn_header_close",
    on: {
      "click": _vm.backClickHandler
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("통합검색 닫기")])]), _c('div', {
    staticClass: "inp_wrap type_search"
  }, [_c('div', {
    staticClass: "inp_box"
  }, [_c('input', {
    staticClass: "inp_element",
    attrs: {
      "type": "search",
      "placeholder": "검색어를 입력해 주세요.",
      "title": "검색어를 입력해 주세요.",
      "maxlength": "30"
    },
    domProps: {
      "value": _vm.searchInputValue
    },
    on: {
      "input": _vm.searchInputHandler,
      "keyup": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchClickHandler.apply(null, arguments);
      }, _vm.keyupHandler]
    }
  }), _vm.visibleState.clearButton ? _c('button', {
    staticClass: "btn_inp_clear",
    on: {
      "click": _vm.searchClearClickHandler
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("입력 내용 삭제")])]) : _vm._e()])]), _c('button', {
    staticClass: "btn_search",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.searchClickHandler
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("검색")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }