var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "header_gnb"
  }, [_c('div', {
    staticClass: "gnb_wrap",
    attrs: {
      "id": "gnbWrap"
    }
  }, [_c('h2', {
    staticClass: "blind"
  }, [_vm._v("주메뉴")]), _vm.navisVisible.first ? _c('NavSwiper', {
    attrs: {
      "class-name": _vm.navisClassName.first,
      "navis": _vm.navis.first,
      "container-class-name": "gnb_list"
    }
  }) : _vm._e(), _vm.navisVisible.second ? _c('NavSwiper', {
    key: _vm.navis.second.map(function (item) {
      return item.name;
    }).join(),
    attrs: {
      "class-name": _vm.navisClassName.second,
      "navi-swiper-class-name": _vm.naviSwiperClassName,
      "navis": _vm.navis.second,
      "active-route-path": _vm.activeRoutePath,
      "container-class-name": "gnb_list"
    }
  }) : _vm._e(), _vm.navisVisible.third ? _c('NavSwiper', {
    key: _vm.navis.third.map(function (item) {
      return item.name;
    }).join(),
    attrs: {
      "class-name": _vm.navisClassName.third,
      "navis": _vm.navis.third,
      "active-route-path": _vm.activeRoutePath,
      "container-class-name": "gnb_detail_list",
      "navi-class-name": "gnb_detail_item",
      "link-class-name": "gnb_detail_menu",
      "link-text-class-name": "gnb_detail_txt"
    }
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }