import { render, staticRenderFns } from "./FooterLayout.vue?vue&type=template&id=23f6eb44&scoped=true&"
import script from "./FooterLayout.vue?vue&type=script&lang=ts&"
export * from "./FooterLayout.vue?vue&type=script&lang=ts&"
import style0 from "./FooterLayout.vue?vue&type=style&index=0&id=23f6eb44&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f6eb44",
  null
  
)

export default component.exports