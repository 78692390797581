var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "wrapperRef",
    class: _vm.classes
  }, [_c('button', {
    ref: "buttonRef",
    staticClass: "btn_optbox",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.selectClickHandler
    }
  }, [_vm._v(" " + _vm._s(_vm.value ? _vm.getText(_vm.value) : _vm.placeholder) + " ")]), _c('div', {
    staticClass: "option_lst"
  }, [_c('ul', _vm._l(_vm.options, function (option, index) {
    return _c('li', {
      key: index
    }, [_c('button', {
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.optionClickHandler(option);
        }
      }
    }, [_vm._v(_vm._s(_vm.getText(option)))])]);
  }), 0)]), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "optbox_employment_type"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }