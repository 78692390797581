import { render, staticRenderFns } from "./WConfirmManager.vue?vue&type=template&id=5ff24bda&"
import script from "./WConfirmManager.vue?vue&type=script&lang=ts&"
export * from "./WConfirmManager.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports