var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "containerRef",
    staticClass: "swiper",
    class: _vm.className
  }, [_c('ul', {
    staticClass: "swiper-wrapper",
    class: _vm.containerClassName
  }, _vm._l(_vm.visibleNavis, function (item, index) {
    return _c('li', {
      directives: [{
        name: "resize-observe",
        rawName: "v-resize-observe",
        value: _vm.slideResizeObserveHandler,
        expression: "slideResizeObserveHandler"
      }],
      key: item.name,
      class: [_vm.naviSwiperClassName, _vm.naviClassName, _vm.isActiveMenu(item.name)],
      on: {
        "click": function click($event) {
          return _vm.slideClickHandler(index);
        }
      }
    }, [_c('router-link', {
      class: _vm.linkClassName,
      attrs: {
        "to": {
          name: item.name
        }
      }
    }, [_c('span', {
      class: _vm.linkTextClassName
    }, [_vm._v(_vm._s(item.text))])])], 1);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }