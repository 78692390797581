var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "header_lnb",
    class: {
      'is-active': _vm.isActive
    }
  }, [_c('div', {
    staticClass: "lnb_wrap",
    attrs: {
      "id": "lnbWrap"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "lnb_top"
  }, [_c('div', {
    staticClass: "lnb_top_user"
  }, [_vm.isLogin ? _c('strong', {
    staticClass: "lnb_top_text"
  }, [_c('span', {
    staticClass: "user_name"
  }, [_vm._v(_vm._s(_vm.userName))]), _vm._v(" 님, 안녕하세요. ")]) : _c('a', {
    staticClass: "btn_login",
    attrs: {
      "href": "#none"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.buttonClickHandler(_vm.BUTTON_ACTIONS.LOGIN);
      }
    }
  }, [_c('span', {
    staticClass: "btn_text"
  }, [_vm._v("로그인 해주세요.")])])]), _c('div', {
    staticClass: "lnb_top_util"
  }, [_vm.isLogin ? [_c('a', {
    staticClass: "btn_size1 btn_primary_outline3",
    attrs: {
      "href": "#none"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.buttonClickHandler(_vm.BUTTON_ACTIONS.MY_PAGE);
      }
    }
  }, [_c('span', {
    staticClass: "btn_text"
  }, [_vm._v("마이페이지")])]), _c('a', {
    staticClass: "btn_size1 btn_primary_outline3",
    attrs: {
      "href": "#none"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.buttonClickHandler(_vm.BUTTON_ACTIONS.LOGOUT);
      }
    }
  }, [_c('span', {
    staticClass: "btn_text"
  }, [_vm._v("로그아웃")])])] : [_c('a', {
    staticClass: "btn_size1 btn_primary_outline3",
    attrs: {
      "href": "#none"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.buttonClickHandler(_vm.BUTTON_ACTIONS.LOGIN);
      }
    }
  }, [_c('span', {
    staticClass: "btn_text"
  }, [_vm._v("LOGIN")])]), _c('a', {
    staticClass: "btn_size1 btn_primary_outline3",
    attrs: {
      "href": "#none"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.buttonClickHandler(_vm.BUTTON_ACTIONS.ENG);
      }
    }
  }, [_c('span', {
    staticClass: "btn_text"
  }, [_vm._v("ENG")])])]], 2)]), _c('div', {
    staticClass: "lnb_body"
  }, [_c('ul', {
    staticClass: "lnb_list"
  }, _vm._l(_vm.SNB_MENU_DATA, function (item, index) {
    return _c('li', {
      key: item.name || item.text,
      staticClass: "lnb_item",
      class: {
        'is-active': _vm.activeIndex === index
      }
    }, [_c('a', {
      staticClass: "lnb_menu",
      attrs: {
        "href": "#none"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.click1DepthHandler(index);
        }
      }
    }, [_c('span', {
      staticClass: "lnb_menu_txt"
    }, [_vm._v(_vm._s(item.text))])]), item.children && item.children.length > 0 ? _c('ul', {
      staticClass: "lnb_detail"
    }, _vm._l(item.children, function (deepItem) {
      return _c('li', {
        key: deepItem.name || deepItem.text,
        staticClass: "lnb_detail_item"
      }, [deepItem.link ? _c('a', {
        staticClass: "lnb_detail_menu",
        attrs: {
          "href": deepItem.link,
          "target": "_blank",
          "title": "새창열림"
        }
      }, [_c('span', {
        staticClass: "lnb_detail_txt"
      }, [_vm._v(_vm._s(deepItem.text))])]) : _c('a', {
        staticClass: "lnb_detail_menu",
        attrs: {
          "href": "#none"
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return _vm.click2DepthHandler(deepItem);
          }
        }
      }, [_c('span', {
        staticClass: "lnb_detail_txt"
      }, [_vm._v(_vm._s(deepItem.text))])])]);
    }), 0) : _vm._e()]);
  }), 0)]), _c('button', {
    staticClass: "btn_close",
    on: {
      "click": _vm.closeMenuHandler
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("전체 메뉴닫기")])])]), _vm._m(1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn_close"
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("전체 메뉴닫기")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lnb_footer"
  }, [_c('h1', {
    staticClass: "logo_text"
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("위즈덤하우스")])]), _c('cite', {
    staticClass: "lnb_footer_copy"
  }, [_vm._v("Copyright © WISDOMHOUSE Inc, All Rights Reserved.")])]);
}]

export { render, staticRenderFns }