var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inp_group type_upload",
    class: {
      'is-attach': _vm.fileNameList.length
    }
  }, [_c('div', {
    staticClass: "inp_wrap inp_file"
  }, [_c('div', {
    staticClass: "inp_box"
  }, [_c('input', {
    ref: "fileInputRef",
    attrs: {
      "id": _vm.id,
      "type": "file",
      "multiple": _vm.multiple,
      "accept": _vm.accept
    },
    on: {
      "change": _vm.fileHandler
    }
  }), _c('label', {
    staticClass: "label_file",
    attrs: {
      "for": _vm.id
    }
  }, [_c('span', {
    staticClass: "label_text"
  }, [_vm._v(_vm._s(_vm.buttonText))])])])]), _c('div', {
    staticClass: "file_upload_wrap"
  }, [!_vm.fileNameList.length ? _c('p', {
    staticClass: "file_upload_guide"
  }, [_vm._v(_vm._s(_vm.guideText))]) : _c('ul', _vm._l(_vm.fileNameList, function (file, index) {
    return _c('li', {
      key: index
    }, [_c('div', {
      staticClass: "btn_file_upload"
    }, [_c('div', {
      staticClass: "btn_file_name"
    }, [_c('span', {
      staticClass: "btn_text"
    }, [_c('em', {
      staticClass: "file_name"
    }, [_vm._v(_vm._s(_vm.fileNameList[index].fileName))]), _c('em', {
      staticClass: "file_type"
    }, [_vm._v(_vm._s(_vm.fileNameList[index].fileExt))])])]), _c('button', {
      staticClass: "btn_delete",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFileHandler(index);
        }
      }
    }, [_c('span', {
      staticClass: "blind"
    }, [_vm._v("첨부파일 삭제")])])])]);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }