var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-final-modal', _vm._g(_vm._b({}, 'vue-final-modal', _vm.sanitizeProps(), false), _vm.sanitizeListeners()), [_c('div', {
    staticClass: "header"
  }, [!_vm.hideTitle ? _c('div', {
    staticClass: "title"
  }, [_vm._t("title")], 2) : _vm._e()]), _c('div', {
    class: _vm.bodyClasses,
    style: {
      width: _vm.width
    }
  }, [_vm._t("default")], 2), !_vm.hideFooter ? _c('div', {
    staticClass: "footer"
  }, [_vm._t("footer")], 2) : _vm._e(), _c('button', {
    staticClass: "btn-close",
    on: {
      "click": _vm.closeHandler
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }