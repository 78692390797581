var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inp_set type_timer"
  }, [_c('WInput', _vm._b({
    attrs: {
      "hide-error-text": "",
      "validate-when-blurred": false
    },
    on: {
      "input": _vm.inputHandler,
      "blur": _vm.blurHandler,
      "validate": _vm.validate
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('span', {
          staticClass: "time_text"
        }, [_c('em', {
          staticClass: "blind"
        }, [_vm._v(_vm._s(_vm.remainTimeTitle))]), _vm._v(" " + _vm._s(_vm.remainTimeStr) + " ")])];
      },
      proxy: true
    }])
  }, 'WInput', _vm.wInputPops, false)), _vm._t("button")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }