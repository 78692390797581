var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_vm.initDataLoaded ? [_c('router-view')] : _vm._e(), _c('WConfirmManager'), _c('WAlertManager'), _vm.isLoading ? _c('WLoading') : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }