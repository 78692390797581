var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "swiper_slide_wrap",
    class: _vm.className
  }, [_c('div', {
    ref: "containerRef",
    staticClass: "swiper",
    class: _vm.containerClassName
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_vm._t("default")], 2)]), _vm.usePagination ? _c('div', {
    ref: "paginationRef",
    staticClass: "swiper_pagination"
  }) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }