var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form_item",
    class: {
      form_border_top: _vm.borderTop,
      insert: _vm.isHorizontally
    }
  }, [_vm.label ? [_c('div', {
    staticClass: "form_cell"
  }, [_c('strong', {
    staticClass: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.label)), _vm.isRequired ? _c('span', {
    staticClass: "txt_essential"
  }, [_c('em', {
    staticClass: "blind"
  }, [_vm._v("필수 입력")])]) : _vm._e()])]), _c('div', {
    staticClass: "form_cell"
  }, [_vm._t("default"), _c('p', {
    staticClass: "inp_validation",
    style: _vm.messageStyle
  }, [_vm._v(_vm._s(_vm.errorMessage))])], 2)] : [!_vm.label ? _vm._t("default") : _vm._e(), !_vm.label ? _c('p', {
    staticClass: "inp_validation",
    style: _vm.messageStyle
  }, [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }