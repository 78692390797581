var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "wInputRef",
    staticClass: "inp_wrap",
    class: {
      'is-error': !_vm.isValid
    }
  }, [_c('div', {
    staticClass: "inp_box",
    class: {
      'is-active': _vm.isActive
    }
  }, [_c('input', {
    ref: "inputRef",
    staticClass: "inp_element",
    attrs: {
      "disabled": _vm.disabled,
      "type": _vm.type,
      "placeholder": _vm.placeholder,
      "title": _vm.title,
      "maxlength": _vm.maxLength,
      "inputmode": _vm.inputMode
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": _vm.inputHandler,
      "change": _vm.changeHandler,
      "blur": _vm.blurHandler,
      "focus": _vm.focusHandler
    }
  }), _vm._t("append")], 2), _vm._t("appendOuter")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }